import Vue from 'vue'
import Vuex from 'vuex'
import state from './modules/state'
import exampleModule from './modules/exampleModule'
import activities from './modules/activities/activities'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    state,
    exampleModule,
    activities,
  },
})
