import axios from 'axios'
import { getLocaleName } from '../../../locale/utils/localeName'
import i18n from '../../../i18n'
import router from '../../../router'
import { EXTENSION_API_BASE_URL } from '@/config/environment'

const state = {
  activitiesData: [],
  calendarEvents: [],
  intervalMin: 1,
  intervalCount: 1440,
  categoriesType: [],
  colors: process.env.VUE_APP_EVENT_COLOR ? process.env.VUE_APP_EVENT_COLOR.split(',') : ['blue'],
  lockedEvent: [],
  token: null,
  type: null,
  date: null,
  bookingTypes: [
    { name: i18n.t('type.in_venue'), value: 'IN_VENUE' },
    { name: i18n.t('type.room_service'), value: 'ROOM_SERVICE' },
  ],
  selectedDatePickerValue: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
  day: null,
  activitiesSelected: [],
}

const getters = {
  token: state => state.token,
}

const mutations = {
  SET_ACTIVITIES_SELECTED (state, activities) {
    state.activitiesSelected = activities
  },
  SET_DATE (state, date) {
    if (date != null) {
      state.date = date
    } else {
      state.date = state.selectedDatePickerValue
    }
  },
  SET_TYPE (state, type) {
    if (type != null && type.length > 0) {
      if (type[0] == 'IN_VENUE') {
        state.type = {
          name: i18n.t('type.in_venue'),
          value: type[0],
        }
      } else {
        state.type = {
          name: i18n.t('type.room_service'),
          value: type[0],
        }
      }
    } else {
      switch (type) {
      case 'IN_VENUE':
        state.type = {
          name: i18n.t('type.in_venue'),
          value: type,
        }
        break
      case 'ROOM_SERVICE':
        state.type = {
          name: i18n.t('type.room_service'),
          value: type,
        }
        break
      default:
        state.type = {
          name: i18n.t('type.in_venue'),
          value: 'IN_VENUE',
        }
        break
      }
    }
  },
  SET_ACTIVITIES (state, activities) {
    state.activitiesData = activities
  },
  SET_ACTIVITIES_RESERVATION (state, activities) {
    state.calendarEvents = activities
  },
  SET_CATEGORIES_TYPE (state, categories) {
    state.categoriesType = categories
  },
  SET_FIRST_DAY (state, day) {
    state.day = day
  },
}

const actions = {
  firstDayOfWeek (context) {
    const locale = (navigator.language || navigator.userLanguage).split('-')[0]
    if (locale == 'es') {
      context.commit('SET_FIRST_DAY', 1)
    } else {
      context.commit('SET_FIRST_DAY', 0)
    }
  },
  getActivities (context, getActivitiesSelected) {
    return new Promise((resolve, reject) => {
      axios.get('/activities', {
        headers: {
          'x-api-key': `${ this.state.state.token }`,
        },
      })
        .then((res) => {
          const activities = res.data.filter(({ page_id }) => !!page_id).map(item => {
            let name = getLocaleName(item.name)
            return {
              pageId: item.page_id,
              name: name,
              value: item.id,
            }
          })
          context.commit('SET_ACTIVITIES', activities)
          getActivitiesSelected()
          resolve(activities)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  },
  getActivitiesReservation (context, filter) {
    const getDateFromSimpleFormatString = function (value) {
      const arrayValue = value.split('-')
      return new Date(arrayValue[2], arrayValue[1] - 1, arrayValue[0])
    }
    const createEventsByInterval = function (eventActivityData, startHour, slotNumber) {
      const slotsEvent = []
      const endHourActivity = new Date(startHour)
      const fullEventColor = process.env.VUE_APP_FULL_RESERVATION_COLOR
      const capacityByAttendeessCode = process.env.VUE_APP_CAPACITY_CONTROL_MODE_BY_ATTENDEES_CODE ? process.env.VUE_APP_CAPACITY_CONTROL_MODE_BY_ATTENDEES_CODE : ''
      for (let index = 0; index < slotNumber; index++) {
        const startAcitity = new Date(endHourActivity)
        endHourActivity.setMilliseconds(eventActivityData.reservationTimeIntervalMs)
        const slotReservations = eventActivityData.activityReservationsStart.filter(item => item.reservationStartDate.getTime() == startAcitity.getTime())
        const reservationsQuantity = eventActivityData.capacityControlMode == capacityByAttendeessCode ? slotReservations.map(item => item.attendeesQuantity).reduce((a, b) => a + b, 0) : slotReservations.length
        const full = !!eventActivityData.maximumCapacityPerSlot && eventActivityData.maximumCapacityPerSlot <= reservationsQuantity
        slotsEvent.push({
          name: eventActivityData.maximumCapacityPerSlot ? `${ i18n.t('calendarEvents.reservations') }: ${ reservationsQuantity }/${ eventActivityData.maximumCapacityPerSlot }` : `${ i18n.t('calendarEvents.reservations') }: ${ reservationsQuantity }`,
          start: startAcitity,
          end: new Date(endHourActivity),
          color: !!full ? fullEventColor : eventActivityData.color,
          timed: true,
          category: eventActivityData.activityName,
          textColor: !!full? eventActivityData.color : fullEventColor,
          slotReservations: slotReservations,
          type: filter.type,
          activityId: eventActivityData.activityId,
          full: !!full,
          eventcolor: eventActivityData.color,
        })
      }
      return slotsEvent
    }
    const getSlotNumber = function (startHour, endHour, reservationTimeIntervalMs) {
      return Math.trunc((endHour.getTime() - startHour.getTime()) / reservationTimeIntervalMs)
    }
    const getFromYesterdaySlotNumberAndStart = function (yesterdayMidnightAvaiableHour, yesterdayFilterDate, activityDayEndHour, reservationTimeIntervalMs) {
      const yesterDayStart = new Date(yesterdayFilterDate)
      yesterDayStart.setMilliseconds(yesterdayMidnightAvaiableHour.startHour)
      const yesterDayEnd = new Date(yesterdayFilterDate)
      yesterDayEnd.setMilliseconds(yesterdayMidnightAvaiableHour.endHour)
      yesterDayStart.setMilliseconds(reservationTimeIntervalMs * getSlotNumber(yesterDayStart, yesterDayEnd, reservationTimeIntervalMs))
      const slotNumber = getSlotNumber(yesterDayStart, activityDayEndHour, reservationTimeIntervalMs)
      return {slotNumber : slotNumber, yesterDayStart: yesterDayStart}
    }
    const getToTomorowSlotNumber = function (tomorowMidnightAvaiableHour, tomorowFilterDate, activityDayStarHour, activityDayEndHour, reservationTimeIntervalMs) {
      const tomorowDayEnd = new Date(tomorowFilterDate)
      tomorowDayEnd.setMilliseconds(tomorowMidnightAvaiableHour.endHour)
      let slotNumber = getSlotNumber(activityDayStarHour, activityDayEndHour, reservationTimeIntervalMs)
      const lastEventHour = new Date(activityDayStarHour)
      lastEventHour.setMilliseconds(slotNumber * reservationTimeIntervalMs)
      if (lastEventHour.getTime() < activityDayEndHour.getTime()) {
        lastEventHour.setMilliseconds(reservationTimeIntervalMs)
        if(lastEventHour.getTime() < tomorowDayEnd.getTime())
          slotNumber += 1
      }
      return slotNumber
    }
    const selectedDay = getDateFromSimpleFormatString(filter.date)
    const token = this.state.state.token
    const config = {
      headers: { authorization: `Bearer ${ token ? token : '' }` },
      params: filter,
    }
    return new Promise((resolve, reject) => {
      axios.get(`${ EXTENSION_API_BASE_URL }/activityreservation`, config)
        .then((res) => {
          let actualColor = 0
          let newEvents = []
          const totalDayIntervalCount = 1440
          context.state.intervalMin = (res.data.minIntervalValueMs / 1000) / 60
          context.state.intervalCount = Math.trunc(totalDayIntervalCount / context.state.intervalMin)
          let categoriesType = res.data.activities.map((item) => getLocaleName(JSON.parse(item.activityInfo.name)))
          context.commit('SET_CATEGORIES_TYPE', categoriesType)
          context.state.lockedEvent = []
          for (let index = 0; index < res.data.activities.length; index++) {
            const activity = res.data.activities[index]
            context.state.lockedEvent = context.state.lockedEvent.concat(activity.excludedSlot.map(item => {
              const lockedDate = new Date(item.date)
              lockedDate.setMilliseconds(item.hour)
              return {
                activityId: item.activityId,
                lockedDate: lockedDate,
              }
            }))
            const eventActivityData = {}
            eventActivityData.selectedDay = selectedDay
            eventActivityData.activityId = activity.activityInfo.activityId
            eventActivityData.capacityControlMode = activity.activityInfo.capacityControlMode
            eventActivityData.reservationTimeIntervalMs = activity.activityInfo.reservationTimeIntervalMs
            eventActivityData.activityName = getLocaleName(JSON.parse(activity.activityInfo.name))
            eventActivityData.maximumCapacityPerSlot = activity.activityInfo.maximumCapacityPerSlot
            eventActivityData.activityReservationsStart = activity.reservations.map((item) => {
              const reservationStartDate = getDateFromSimpleFormatString(item.resquetedDate)
              reservationStartDate.setMilliseconds(item.requestedTimeMs)
              return {reservationStartDate: reservationStartDate, reservationId: item.id, activityId: item.activityId, attendeesQuantity: item.nominalAttendeesQuantity + item.numeralAttendeesQuantity}
            })
            eventActivityData.color = context.state.colors[actualColor]
            const yesterdayFilterDate = new Date(selectedDay)
            yesterdayFilterDate.setDate(yesterdayFilterDate.getDate()-1)
            const tomorowFilterDate =  new Date(selectedDay)
            tomorowFilterDate.setDate(tomorowFilterDate.getDate()+1)
            let slotNumber = 0
            for (let index = 0; index < activity.avaiableHour.length; index++) {
              const aHour = activity.avaiableHour[index]
              slotNumber = 0
              let activityDayStarHour = new Date(selectedDay)
              activityDayStarHour.setMilliseconds(aHour.startHour)
              const activityDayEndHour = new Date(selectedDay)
              activityDayEndHour.setMilliseconds(aHour.endHour)
              if (aHour.startHour == 0 && activity.yesterdayMidnightAvaiableHour) {
                const yesterdayResult = getFromYesterdaySlotNumberAndStart(activity.yesterdayMidnightAvaiableHour, yesterdayFilterDate, activityDayEndHour, eventActivityData.reservationTimeIntervalMs)
                activityDayStarHour = yesterdayResult.yesterDayStart
                slotNumber = yesterdayResult.slotNumber
              }
              if (aHour.endHour == 86399999 && activity.tomorowMidnightAvaiableHour) {
                slotNumber = getToTomorowSlotNumber(activity.tomorowMidnightAvaiableHour, tomorowFilterDate, activityDayStarHour, activityDayEndHour, eventActivityData.reservationTimeIntervalMs)
              }
              if (!slotNumber)
                slotNumber = getSlotNumber(activityDayStarHour, activityDayEndHour, activity.activityInfo.reservationTimeIntervalMs)
              const aHourEvent = createEventsByInterval(eventActivityData, activityDayStarHour, slotNumber)
              newEvents = newEvents.concat(aHourEvent)
            }
            actualColor = actualColor == context.state.colors.length - 1 ?  0 : actualColor + 1
          }
          context.commit('SET_ACTIVITIES_RESERVATION', newEvents)
          resolve()
        })
        .catch((error) => {
          if (error.response && error.response.status == 401) {
            router.push('/unauthorized')
            reject()
          }
        })
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}