<template>
  <v-app>
    <v-app-bar dark v-if="$route.name" color="secondary lighten-5" flat app>
      <v-icon color="white" class="big-icon">local_play</v-icon>
      <div class="text-h4 ml-4 white--text">{{$t(`title.${$route.name}`)}}</div>
      <v-spacer/>
      <logo theme="dark" class="login-logo" height="60"/>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-0">
        <router-view/>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="state.message.show"
      bottom
      :color="state.message.color"
      :timeout="state.message.timeout"
    >
      {{ state.message.text }}
    </v-snackbar>
  </v-app>
</template>
<script>
import { nextTick } from 'process'
import { mapState } from 'vuex'
import axios from 'axios'
export default {
  data () {
    return {
      activitiesSelected: null,
      type: null,
    }
  },
  components: {
    Logo: () => import('@/components/Logo.vue'),
  },
  watch: {
    '$route.query' () {
      if (this.$route.query['types'] != null) {
        this.type = this.$route.query['types'].split(',')
      } else {
        this.type = this.$route.query['types']
      }

      this.$store.commit('state/setToken', this.$route.query['x-api-key'])
      this.$store.commit('activities/SET_DATE', this.$route.query['since_date'])
      if (this.$route.query['activity_ids'] != null) {
        this.activitiesSelected = this.$route.query['activity_ids'].split(',')
      } else {
        this.activitiesSelected = this.$route.query['activity_ids']
      }
      this.$store.commit('activities/SET_ACTIVITIES_SELECTED', this.activitiesSelected)
      this.$store.commit('activities/SET_TYPE', this.type)
    },
  },
  computed: {
    ...mapState(['state']),
  },
}
</script>
<style lang="scss">
html {
  overflow-y: auto !important;
  overflow-x: hidden !important;
}
.big-icon {
  font-size: 38px !important;
}
.flex-none {
  flex: none !important;
}

// VUETIFY
.v-content {
  max-height: 100vh;
  .v-content__wrap {
    overflow: auto;
    position: relative;
  }
}
.v-btn:not(.v-btn--floating):not(.v-btn--icon) {
  border-radius: 5px !important;
}
.v-input {
  position: relative;
}
.v-application--is-ltr .v-input__prepend-outer {
  position: absolute;
  right: 0;
}
</style>
