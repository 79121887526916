export const getLocaleName = (localeNames) => {
  const defaultLanguage = process.env.VUE_APP_DEFAULT_LANGUAGE ? process.env.VUE_APP_DEFAULT_LANGUAGE : ''
  if(!localeNames.length)
    return []

  const locale = (navigator.language || navigator.userLanguage).split('-')[0]
  let name = localeNames.find(item => item.language_code == locale )
  if(!name)
    name = localeNames.find(item => item.language_code == defaultLanguage )
  return name ? name.value : localeNames[0].value
}