import Vue from 'vue'
import VueRouter from 'vue-router'
import { BASE_URL } from '@/config/environment'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: () => import('../views/AvailabilityCalendar.vue'),
    meta: { icon: 'local-hotel' },
  },
  {
    path: '/unauthorized',
    name: 'unauthorized',
    component: () => import('../views/UnauthorizedView.vue'),
  },
  {
    path: '*',
    redirect: { name: 'main' },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: BASE_URL,
  routes,
})

export default router
