export default {
  actions: {
    submit: 'Enviar',
  },
  calendarEvents: {
    reservations: 'Reservas',
  },
  filter: {
    check_availability: 'Consultar Disponibilidad',
    booking_type: 'Tipo de reserva',
    activity: 'Actividad',
    date: 'Fecha',
  },
  title: {
    main: 'Capacidad diaria',
    unauthorized: 'No autorizado',
  },
  type: {
    in_venue: 'Reserva',
    room_service: 'Servicio de habitación',
  },
  message: {
    complete_main: 'Proceso completado. Puedes cerrar esta ventana.',
  },
  errorMessage: {
    page_unauthorized: 'Error: (401) no autorizado',
    page_unauthorized_description:'El token de seguridad ha caducado, la acción ha sido cancelada.',
  },
  emptyEventsMessage: {
    emptyMessage: 'No hay datos disponibles',
  },
  loading: {
    loadingText: 'Cargando...',
  },
}
