export default {
  actions: {
    submit: 'Submit',
  },
  calendarEvents: {
    reservations: 'Reservations',
  },
  filter: {
    check_availability: 'Check Availability',
    booking_type: 'Booking type',
    activity: 'Activity',
    date: 'Date',
  },
  title: {
    main: 'Daily capacity',
    unauthorized: 'Unauthorized',
  },
  type: {
    in_venue: 'In venue',
    room_service: 'Room service',
  },
  message: {
    complete_main: 'Process complete. You can close this window.',
  },
  errorMessage: {
    page_unauthorized: 'Error: (401) unauthorized',
    page_unauthorized_description:'The security token has expired, the action has been cancelled.',
  },
  emptyEventsMessage: {
    emptyMessage: 'No data available',
  },
  loading: {
    loadingText: 'Loading...',
  },
}
